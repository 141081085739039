.sidebar {
  width: 14rem;
  height: 100vh;
  transition: all 0.3s;
  box-shadow: 7px 1px 15px rgba(0, 0, 0, 0.911);

  /* Ajustes para dispositivos móviles */
  @media only screen and (max-width: 480px) {
    width: 100%;
    /* Ajustamos el ancho del sidebar para dispositivos móviles */
    height: auto;
    /* Ajustamos la altura del sidebar para dispositivos móviles */
  }
}

.offcanvas {
  background-color: rgba(10, 49, 67, 0.7);
  box-shadow: 7px 1px 15px rgba(0, 0, 0, 0.911);
}

.offcanvas-header .btn-close {
  color: #ffffffa2;
  background-color: #ffffffa2;
  border-color: #ddd;
}

.offcanvas-header .btn-close:hover {
  color: #ffffffa2;
    background-color: #ffffffa2;
    border-color: #ddd;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}

.logo {
  font-size: 30px;
}

.bars {
  background-color: rgba(10, 49, 67, 0.85);
  border-color: rgba(10, 49, 67, 0.6);
  color: #fff;
  display: flex;
  font-size: 25px;
  margin-left: 10px;
}

.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}

.link:hover {
  background: #e78b00;
  color: #000;
  transition: all 0.5s;
}

.active {
  background: #df5200;
  color: #000;
}

.icon,
.link_text {
  font-size: 20px;
}

.text-user {
  text-align: center;
  color: #fff;
  font-size: 20px;
}

.align-i {
  text-align: center;
}

/* Media queries */
/* Móvil */
@media only screen and (max-width: 480px) {
  .sidebar {
    margin-left: 0px;
  }

  .sidebar.active {
    margin-left: 0px;
  }

  .sidebarCollapse span {
    display: none;
  }
}

/* Tableta */
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .sidebar {
    margin-left:0px;
  }

  .sidebar.active {
    margin-left: 0px;
  }

  .sidebarCollapse span {
    display: block;
  }
}

/* Laptop */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .sidebar {
    margin-left: 0px;
  }

  .sidebar.active {
    margin-left: 0px;
  }

  .sidebarCollapse span {
    display: block;
  }
}

/* Escritorio */
@media only screen and (min-width: 1025px) {
  .sidebar {
    margin-left: 0px;
  }

  .sidebar.active {
    margin-left: 0px;
  }

  .sidebarCollapse span {
    display: block;
  }
}