// Variables
$primary-color: #df5200;
$secondary-color: rgba(10, 49, 67, 0.85);
$background-color: rgba(232, 232, 232, 0.5);
$border-color: rgba(10, 49, 67);

// Componentes
.dashboard {
  .tral,
  .tra {
    font-size: 1rem;
    border-radius: 3px;
    background-color: $background-color;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.91);
  }

  .card {
    background-color: $background-color;
    margin: 10px;
    border-radius: 5px;
  }
}

// Formularios
.frm {
  padding: 30px;
  background-color: $background-color;
}

// Inputs
.inputBuscar {
  width: 50% !important;
}

.containerInput {
  align-items: end;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 25px;
  padding-right: 25px;
}

// Media queries
// Móvil
@media only screen and (max-width: 480px) {
  .inputBuscar {
    width: 100% !important;
  }

  .containerInput {
    padding-bottom: 15px;
  }

  .alert {
    font-size: 12px;
    padding: 8px;
  }
}

// Tableta
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .inputBuscar {
    width: 75% !important;
  }

  .containerInput {
    padding-bottom: 20px;
  }

  .alert {
    font-size: 14px;
    padding: 10px;
  }
}

// Laptop
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .inputBuscar {
    width: 50% !important;
  }

  .containerInput {
    padding-bottom: 25px;
  }

  .alert {
    font-size: 16px;
    padding: 12px;
  }
}

// Escritorio
@media only screen and (min-width: 1025px) {
  .inputBuscar {
    width: 33% !important;
  }

  .containerInput {
    padding-bottom: 30px;
  }

  .alert {
    font-size: 18px;
    padding: 15px;
  }
}

// Estilos generales
#main_content {
  padding: 40px;
}

#ali {
  bottom: 0px;
  position: absolute;
}

.title-form {
  padding: 0.5rem;
  background-color: $secondary-color;
  color: rgb(255 255 255 / 80%);
  text-align: center;
}

#fotb {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.page-item.active.page-link {
  z-index: 3;
  color: #ffffff;
  background-color: $primary-color;
  border-color: $primary-color;
}

.tr1 {
  border-radius: 3px;
  vertical-align: initial;
}

#but {
  background: $primary-color;
  border-color: $primary-color;
  color: #ffffff;
}

#tabl {
  --bs-table-bg: $background-color;
  font-size: 0.9rem;
  border-color: $border-color;

  th {
    color: #fff;
  }
}

.header_m {
  color: white;
  background-color: $secondary-color;
}

#stahead {
  position: sticky;
  top: 0;
  background-color: $secondary-color;
}

///notificacion Dashboard
@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

////drop tabla
.dropdown-menu {
  background-color: rgba(10, 49, 67, 0.9);
  padding: 0;

  .dropdown-item {
    text-decoration: none;
    color: white;

    a {
      text-decoration: none;
      color: white;
    }
  }
}
