#form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 1;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.7);
  background-image: linear-gradient(210deg, #00000042, rgba(10, 49, 67, 1) 90%);
  color: white;
}

/* Estilos para dispositivos móviles (tamaño de pantalla menor a 768px) */
@media (max-width: 768px) {
  #form {
    padding: 20px;
    width: 80%;
  }
  label {
    font-size: 16px;
  }
  p {
    font-size: 18px;
  }
  .error {
    font-size: 18px;
  }
  .success {
    font-size: 18px;
  }
  .bt {
    font-size: 14px;
  }
  .im {
    font-size: 100px;
  }
}

/* Estilos para dispositivos de escritorio (tamaño de pantalla mayor a 768px) */
@media (min-width: 769px) {
  #form {
    padding: 40px;
    width: 20%;
  }
  label {
    font-size: 18px;
  }
  p {
    font-size: 25px;
  }
  .error {
    font-size: 25px;
  }
  .success {
    font-size: 25px;
  }
  .bt {
    font-size: 16px;
  }
  .im {
    font-size: 150px;
  }
}

label {
  font-family: "Rubik", sans-serif;
}

p {
  color: rgba(10, 49, 67, 1);
  cursor: pointer;
}

.error {
  color: #df5200;
}

.success {
  color: rgb(255, 255, 255);
}

.bt {
  cursor: pointer;
  background-color: #df5200;
  border-color: #df5200;
}

.im {
  color: black;
}
