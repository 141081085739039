.navbar {
  background-image: linear-gradient(20deg, #00000042, rgba(10, 49, 67, 1)90%);
  box-shadow: 7px 1px 15px rgba(0, 0, 0, 0.911);
}

.dropdown-menu {
  --bs-dropdown-link-hover-bg: rgba(0, 0, 0, 0.25);
}

.drop-menu.show {
  background-image: linear-gradient(30deg, #00000042, rgba(10, 49, 67, 1) 80%);
  --bs-dropdown-link-active-bg: rgba(10, 49, 67, 0.8);
  --bs-dropdown-link-color: #ffffff;
}

.header_mo {
  color: white;
  background-color: rgba(10, 49, 67, 0.85);
}

.navbar-toggler {
  /* Estilos para desktop y laptop */
  background-color: #f18b3e;
  /* Color de fondo del toggle */
  border-color: #f18b3e;
  /* Color del borde del toggle */
}

/* Media query para dispositivos móviles */
@media only screen and (max-width: 480px) {
  .navbar-toggler {
    background-color:#f18b3e;
    /* Color de fondo del toggle para dispositivos móviles */
    border-color: #f18b3e;
    /* Color del borde del toggle para dispositivos móviles */
  }
}

.navbar-toggler-icon {
  /* Estilos para desktop y laptop */
  color: #fff;
  /* Color del icono del toggle */
}

/* Media query para dispositivos móviles */
@media only screen and (max-width: 480px) {
  .navbar-toggler-icon {
    color: #333;
    /* Color del icono del toggle para dispositivos móviles */
  }
}

// Media queries
// Móvil
@media only screen and (max-width: 480px) {
  .navbar {
    padding: 10px;
  }

  .dropdown-menu {
    font-size: 14px;
  }

  .header_mo {
    font-size: 16px;
  }
}

// Tableta
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .navbar {
    padding: 15px;
  }

  .dropdown-menu {
    font-size: 16px;
  }

  .header_mo {
    font-size: 18px;
  }
}

// Laptop
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .navbar {
    padding: 15px;
  }

  .dropdown-menu {
    font-size: 16px;
  }

  .header_mo {
    font-size: 18px;
  }
}

// Escritorio
@media only screen and (min-width: 1025px) {
  .navbar {
    padding: 15px;
  }

  .dropdown-menu {
    font-size: 16px;
  }

  .header_mo {
    font-size: 18px;
  }
}