@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap");
* {
  margin: 0;
  padding: 0;
}
Body {
  font-family: "Rubik", sans-serif;
  background-image: url(./components/img/op88.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #efefef;
}

.flex {
  display: flex;
}

:root {
  --dark-background-color: #202124; /* Color de fondo oscuro */
  --dark-text-color: #e8eaed; /* Color de texto oscuro */
  --dark-background-image: none; /* Color de texto oscuro */
}

.dark-mode {
  --background-color: var(--dark-background-color);
  --text-color: var(--dark-text-color);
  color: var(--dark-text-color);
}

Body.dark-mode,
html.dark-mode {
  background-image: var(--dark-background-image);
  background-color: var(--dark-background-color);
}
///Fondos dashboard modo oscuro
.dark-mode .card {
  background-color: rgba(232, 232, 232, 0.5);
}

.dark-mode .form-control {
  background-color: #666666; /* Plomo */
  color: #ffffff;
  border-color: #9b9999;
}

.dark-mode ::placeholder {
  color: #e8eaed;
}

.dark-mode table {
  color: #e8eaed;
}

.dark-mode th,
.dark-mode td {
  color: #e8eaed;
}
.dark-mode #tabl {
  th {
    color: #bdc1c6;
  }
}

.dark-mode .btn-primary {
  background-color: #19179b;
  /* Violeta */
  border-color: #19179b;
  color: #e8eaed;
}

.dark-mode .btn-danger {
  background-color: #3a1242; /* Violeta */
  border-color: #3a1242;
  color: #e8eaed;
}

.dark-mode .btn-warning {
  background-color: #0c2f4b; /* Azul */
  border-color: #0c2f4b;
  color: #e8eaed;
}

.dark-mode .btn-success {
  background-color: #0e3d22; /* Verde oscuro */
  border-color: #0e3d22;
  color: #e8eaed;
}

.dark-mode .modal-body {
  background-color:#666666;
  /* Rojo */
}

.dark-mode .modal-body label {
  color: #FFFFFF;
  /* Blanco */
}

.dark-mode .modal-footer {
  background-color:#666666;
  /* Rojo */
}
////Boton MODOS
.theme-button {
  position: fixed;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333;
  border: 2px solid rgba(10, 49, 67, 0.85);
  padding: 10px 10px;
  border-radius: 20px;
  color: #fff;
  z-index: 1000;
}

.theme-button i {
  margin-right: 10px;
}

.dark-mode .theme-button {
  background-color: #666;
}
.theme-button img {
  width: 10px;
}
